import { gql } from "@solid-primitives/graphql";
import { CategoryModel, CollectionModel, ProfileModel, ColourModel, CataloguePromoModel } from "./types";

export const retrieveBuilderShopDetails = async () => {
  const response = await fetch(
    `https://cdn.builder.io/api/v3/graphql/${
      import.meta.env.VITE_BUILDER_APIKEY
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        variables: {},
        query: gql`
          query {
            collections: collection(limit: 200) {
              name
              data {
                description
                thumbnailImage
                singleMouldingImage
                photoFrameImage
                galleryFrameImage
                hideOnCollectionPage
              }
            }
            categories: category(limit: 200) {
              name
              data {
                featureDescription
                logo
                isNew
              }
            }
            profiles: profile(limit: 200) {
              name
            }
            colours: colour(limit: 200) {
              name
              data {
                colour
                gradient
              }
            }
            promos: cataloguePromo {
              name
              data {
                bgImage
                bgColour
                layout
                textContent
                textColour
                buttonText
                buttonUrl
                buttonColour
                position
                showCategory
                showCollection
                active
              }
            }
          }
        `,
      }),
    }
  );

  if (!response.ok) {
    // TODO
    throw new Error("Error fetching Builder data");
  }

  const data : BuilderShopDetailsResponse= await response.json();
  return data;
};


type BuilderShopDetailsResponse = {
  data: {
    categories: CategoryModel[],
    collections: CollectionModel[],
    profiles: ProfileModel[],
    colours: ColourModel[],
    promos: CataloguePromoModel[],
  }
}